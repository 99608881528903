import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby';
import './PostList.scss';
import RenderContent from '../../RenderContent';
import Pagination from '../../Pagination';
import { Image } from '../../Image';
import PostListItem from '../PostListItem'
import { PostListNavigation } from '../PostListNavigation'

export default class IndexPage extends React.Component {
  render() {
    const { posts, title, pageContext, categoryFilter, pathPrefix, siteMetadata, showAuthor, dateFormat } = this.props
    return (
      <section className="post-list">
        <PostListNavigation pathPrefix={pathPrefix} categoryFilter={categoryFilter} postSlug="/blog/" />
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
                {posts && posts.map((post, inedx) => !post.node.slug.includes('gatsby') &&
                  <PostListItem key={inedx} data={post.node} dateFormat={dateFormat} siteMetadata={siteMetadata} pathPrefix={pathPrefix} showAuthor={showAuthor} />
                )}
              </div>
              <Pagination pageContext={pageContext} pathPrefix={pathPrefix} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

IndexPage.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export const pageQuery = graphql`
  fragment PostListFields on wordpress__POST {
    id
    categories {
      id
      name
      slug
      path
    }
    author {
      name
      slug
      path
      avatar_urls {
        wordpress_48
      }
    }
    title
    excerpt
    date
    slug
    path
    featured_media {
      localFile {
        childImageSharp {
          fluid(maxWidth: 670, quality: 70) {
            ... GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`
